<style scoped></style>

<template>
  <div>
    <fm-table-new
      size="small"
      auto-height
      :stripe="false"
      full
      :columns="columns"
      :data-list="dataList">
    </fm-table-new>
  </div>
</template>

<script>
export default {
  props: {
    dataList: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      columns: [
        { title: '姓名', field: 'name', sort: true },
        { title: '证书专业', field: 'pros', sort: true },
        { title: '职称级别', field: 'level', sort: true },
        { title: '证书名称', field: 'certificateNames', sort: true },
      ]
    }
  }
}
</script>
