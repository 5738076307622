<style scoped lang="less">
  @import './layout.less';
  .search-b {
    background: #fff;
    padding: 5px 10px;
  }
</style>

<template>
  <div class="analysis-medical-page layout">
    <div class="search-b">
      <span style="margin-right: 1rem;">人员类别</span>
      <fm-select @change="loadData" absolute filterable v-model="jobType">
        <fm-option v-for="item in workerJobTypes" :key="item.key" :value="item.key" :label="item.label"></fm-option>
      </fm-select>
    </div>
    <div class="top">
      <Rate :key="loadkey + 'rate'" class="left" @active="v => setActive('rate', v)" :data="rateData" :name="rateName" />
      <Bar :key="loadkey + 'bar'" class="right" @active="v => setActive('bar', v)" :data="barData" />
    </div>
    <details-table :data-list="dataList" :key="loadkey + 'table'" class="bottom" />
    <div class="count">共{{dataList.length}}条数据</div>
  </div>
</template>

<script>
import Bar from './medical/bar.vue'
import Rate from './medical/rate.vue'
import DetailsTable from './medical/details.vue'
import { analysisRequest } from '../../api'

let timer = null

export default {
  components: { Bar, Rate, DetailsTable },
  data () {
    return {
      baseDataList: [],
      dataList: [],
      rateData: [],
      rateName: '',
      barData: {
        types: [],
        series: [],
        name: ''
      },
      loading: {
        load: false
      },
      jobType: 'all',
      loadkey: 0,
      activeData: {}
    }
  },
  methods: {
    resize () {
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.loadkey++
      }, 500)
    },
    setActive (type, data) {
      this.activeData[type] = JSON.parse(JSON.stringify(data))
      let dataList = this.baseDataList

      if (this.activeData.rate && this.activeData.rate.name) {
        dataList = dataList.filter(v => v.yh === this.activeData.rate.name)
      }

      if (this.activeData.bar && this.activeData.bar.name) {
        dataList = dataList.filter(v => v.level === this.activeData.bar.name)
      }

      this.dataList = dataList
      this.generateBarData(this.activeData.rate ? this.activeData.rate.name : null)
      this.generateRateData(this.activeData.bar ? this.activeData.bar.name : null)
    },
    generateBarData (nameSet) {
      const yhTypes = Array.from(new Set(this.baseDataList.map(v => v.yh))).filter(v => !nameSet || nameSet === v)
      const levelType = Array.from(new Set(this.baseDataList.map(v => v.level)))
      this.barData = {
        name: nameSet ? (' - ' + nameSet) : '',
        types: levelType,
        series: yhTypes.map(yh => {
          return {
            name: yh || '其他',
            type: 'bar',
            stack: 'num',
            label: {
              show: true
            },
            selectedMode: 'single',
            itemStyle: {
              opacity: 0.8
            },
            emphasis: {
              itemStyle: {
                opacity: 1
              },
              label: {
                fontWeight: 'bolder',
                fontSize: 16
              }
            },
            // select: {
            //   disabled: true,
            //   label: {
            //     fontWeight: 'bolder',
            //     fontSize: 16
            //   },
            //   itemStyle: {
            //     borderColor: 'rgba(255, 255, 255, .5)',
            //     shadowColor: 'rgba(0, 0, 0, 0.5)',
            //     borderRadius: 5,
            //     shadowBlur: 20,
            //     opacity: 1
            //   }
            // },
            barMaxWidth: 40,
            data: levelType.map(level => {
              return {
                name: level,
                value: this.baseDataList.filter(v => v.level === level && v.yh === yh).length
              }
            })
          }
        })
      }
    },
    generateRateData (nameSet) {
      const yhTypes = Array.from(new Set(this.baseDataList.map(v => v.yh)))
      this.rateName = nameSet ? (' - ' + nameSet) : ''
      this.rateData = yhTypes.map(yh => {
        return {
          name: yh || '其他',
          value: this.baseDataList.filter(v => v.yh === yh && (!nameSet || nameSet === v.level)).length
        }
      })
    },
    async loadData () {
      this.loading.load = true
      let dataList = []
      let parm = {
        jobType: this.jobType,
        isyh: 1,
        isNotLeave: 1
      }
      if (this.$authFunsProxy.get) {
        dataList = await analysisRequest.certificateLevel(parm)
      } else if (this.$authFunsProxy.getMyOrgContainChild) {
        dataList = analysisRequest.certificateLevelMyOrgContainChild(parm)
      } else if (this.$authFunsProxy.getMyOrg) {
        dataList = await analysisRequest.certificateLevelMyOrg(parm)
      }
      this.loading.load = false

      dataList.forEach(v => {
        v.level = v.level || '无职称'
        v.yh = v.yh || '其他'
      })

      this.dataList = this.baseDataList = dataList
      this.generateBarData()
      this.generateRateData()
    }
  },
  computed: {
    workerJobTypes () {
      return [{key: 'all', label: '全部'}].concat(this.$store.getters.selectDatas['worker_job_type'])
    }
  },
  mounted () {
    this.loadData()
    window.addEventListener('resize', this.resize)
  },
  destroyed () {
    window.removeEventListener('resize', this.resize)
  }
}
</script>
