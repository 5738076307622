<style scoped></style>

<template>
  <div></div>
</template>

<script>
import Mixins from '../mixins/rate'
export default {
  mixins: [Mixins],
  methods: {
    getOptions () {
      return {
        title: {
          text: '医护比' + this.name,
          subtext: '  ' + this.data.map(v => v.name).join('/') + '：' + this.data.map(v => v.value).join('/')
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          right: '5%',
          orient: 'vertical'
        },
        series: [
          {
            name: '医护比',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.data
          }
        ]
      }
    }
  }
}
</script>
